define("discourse/plugins/discourse-warner-dc/initializers/ensure-tos-agreed", ["exports", "rsvp", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/plugins/discourse-warner-dc/discourse/components/modal/dc-tos-required"], function (_exports, _rsvp, _ajax, _pluginApi, _dcTosRequired) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "ensure-tos-agreed",
    after: "inject-objects",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.34", api => {
        api.composerBeforeSave(function () {
          return new _rsvp.Promise((resolve, reject) => {
            let {
              user
            } = this;
            if (this.get("category.custom_fields.dc_tos_required") === "t" && !user.dc_tos_agreed) {
              let model = {
                agree() {
                  return (0, _ajax.ajax)("/warner-dc/tos.json", {
                    method: "PUT"
                  }).then(() => {
                    user.set("dc_tos_agreed", new Date());
                    return resolve();
                  }).catch(() => reject());
                },
                disagree: reject
              };
              api.container.lookup("service:modal").show(_dcTosRequired.default, {
                model
              });
            } else {
              resolve();
            }
          });
        });
      });
    }
  };
});