define("discourse/plugins/discourse-warner-dc/discourse/components/modal/dc-tos-required", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal class="dc-tos-required-modal" @title={{i18n "dcu.tos_required.title"}}>
    <:body>
      <div class="dc-tos-scroll-area">
        <CookText @rawText={{i18n "dcu.tos_required.body"}} />
      </div>
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @label="dcu.tos_required.agree"
        @action={{this.agree}}
      />
      <DButton
        class="btn-danger"
        @label="dcu.tos_required.disagree"
        @action={{this.disagree}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "ylLUmzjo",
    "block": "[[[8,[39,0],[[24,0,\"dc-tos-required-modal\"]],[[\"@title\"],[[28,[37,1],[\"dcu.tos_required.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"dc-tos-scroll-area\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@rawText\"],[[28,[37,1],[\"dcu.tos_required.body\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\"],[\"dcu.tos_required.agree\",[30,0,[\"agree\"]]]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-danger\"]],[[\"@label\",\"@action\"],[\"dcu.tos_required.disagree\",[30,0,[\"disagree\"]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"d-modal\",\"i18n\",\"cook-text\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-warner-dc/discourse/components/modal/dc-tos-required.hbs",
    "isStrictMode": false
  });
  class DCTosRequiredModal extends _component2.default {
    agree() {
      this.args.model.agree();
      this.args.closeModal();
    }
    static #_ = (() => dt7948.n(this.prototype, "agree", [_object.action]))();
    disagree() {
      this.args.model.disagree();
      this.args.closeModal();
    }
    static #_2 = (() => dt7948.n(this.prototype, "disagree", [_object.action]))();
  }
  _exports.default = DCTosRequiredModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DCTosRequiredModal);
});